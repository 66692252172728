<div class="flex flex-col gap-0.5">
  <div
    class="flex justify-between items-center accent-background-400 px-6 py-4 cursor-pointer _transition"
    [ngClass]="{'rounded-lg': !active, 'rounded-t-lg': active }"
    (click)="toggleEvent.emit()"
  >
    <p class="flex items-center"><cds-svg-icon *ngIf="icon" [name]="icon" class="mr-2 w-4 h-4"></cds-svg-icon>{{ title }}</p>
    <cds-svg-icon name="arrow-down" class="_transition" [ngClass]="{ 'active': active }"></cds-svg-icon>
  </div>
  <div
    class="grid accent-background-400 rounded-b-lg wrapper _transition"
    [ngClass]="{ 'animate': active }"
  >
    <div class="flex flex-col gap-4 px-6 text-xs _transition overflow-hidden" [ngClass]="{ 'py-4': active }">
      <p [innerHTML]="content"></p>
      <ng-content></ng-content>
    </div>
  </div>
</div>
